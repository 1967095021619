.chunky-input {
  @apply font-bold text-lg sm:text-2xl leading-none sm:leading-none py-2 sm:py-4 px-2 sm:px-8 rounded border-4 border-mental-tertiary-1000;
}

.chunky-button {
  @apply chunky-input text-mental-tertiary-1000;
}

.chunky-button:disabled {
  @apply from-mental-neutral-500 to-mental-neutral-700;
}

.chunky-button-white {
  @apply chunky-button;
}

.chunky-button-white:hover:not(:disabled) {
  @apply -translate-y-0.5
}

.chunky-button-green {
  @apply chunky-button bg-gradient-to-t from-mental-primary-200 to-mental-primary-400;
}

.chunky-button-green:hover:not(:disabled) {
  @apply from-mental-primary-200 to-mental-primary-300;
}
