/* Define the entire Nexa font family in all 9 weights, and in italics */

/* Use Tailwind's `font-thin` */
@font-face {
  font-family: "Nexa";
  src: url("/fonts/Nexa/Nexa-Thin.otf");
  font-style: normal;
  font-display: swap;
  font-weight: 100;
}

@font-face {
  font-family: "Nexa";
  src: url("/fonts/Nexa/Nexa-ThinItalic.otf");
  font-style: italic;
  font-display: swap;
  font-weight: 100;
}

/* Use Tailwind's `font-extralight` */
@font-face {
  font-family: "Nexa";
  src: url("/fonts/Nexa/Nexa-ExtraLight.otf");
  font-style: normal;
  font-display: swap;
  font-weight: 200;
}

@font-face {
  font-family: "Nexa";
  src: url("/fonts/Nexa/Nexa-ExtraLightItalic.otf");
  font-style: italic;
  font-display: swap;
  font-weight: 200;
}

/* Use Tailwind's `font-light` */
@font-face {
  font-family: "Nexa";
  src: url("/fonts/Nexa/Nexa-Light.otf");
  font-style: normal;
  font-display: swap;
  font-weight: 300;
}

@font-face {
  font-family: "Nexa";
  src: url("/fonts/Nexa/Nexa-LightItalic.otf");
  font-style: italic;
  font-display: swap;
  font-weight: 300;
}

/* Use Tailwind's `font-normal` */
@font-face {
  font-family: "Nexa";
  src: url("/fonts/Nexa/Nexa-Book.otf");
  font-style: normal;
  font-display: swap;
  font-weight: 400;
}

@font-face {
  font-family: "Nexa";
  src: url("/fonts/Nexa/Nexa-BookItalic.otf");
  font-style: italic;
  font-display: swap;
  font-weight: 400;
}

/* Use Tailwind's `font-medium` */
@font-face {
  font-family: "Nexa";
  src: url("/fonts/Nexa/Nexa-Regular.otf");
  font-style: normal;
  font-display: swap;
  font-weight: 500;
}

@font-face {
  font-family: "Nexa";
  src: url("/fonts/Nexa/Nexa-RegularItalic.otf");
  font-style: italic;
  font-display: swap;
  font-weight: 500;
}

/* Use Tailwind's `font-semibold` */
@font-face {
  font-family: "Nexa";
  src: url("/fonts/Nexa/Nexa-Bold.otf");
  font-style: normal;
  font-display: swap;
  font-weight: 600;
}

@font-face {
  font-family: "Nexa";
  src: url("/fonts/Nexa/Nexa-BoldItalic.otf");
  font-style: italic;
  font-display: swap;
  font-weight: 600;
}

/* Use Tailwind's `font-bold` */
@font-face {
  font-family: "Nexa";
  src: url("/fonts/Nexa/Nexa-ExtraBold.otf");
  font-style: normal;
  font-display: swap;
  font-weight: 700;
}

@font-face {
  font-family: "Nexa";
  src: url("/fonts/Nexa/Nexa-ExtraBoldItalic.otf");
  font-style: italic;
  font-display: swap;
  font-weight: 700;
}

/* Use Tailwind's `font-extrabold` */
@font-face {
  font-family: "Nexa";
  src: url("/fonts/Nexa/Nexa-Heavy.otf");
  font-style: normal;
  font-display: swap;
  font-weight: 800;
}

@font-face {
  font-family: "Nexa";
  src: url("/fonts/Nexa/Nexa-HeavyItalic.otf");
  font-style: italic;
  font-display: swap;
  font-weight: 800;
}

/* Use Tailwind's `font-black` */
@font-face {
  font-family: "Nexa";
  src: url("/fonts/Nexa/Nexa-Black.otf");
  font-style: normal;
  font-display: swap;
  font-weight: 900;
}

@font-face {
  font-family: "Nexa";
  src: url("/fonts/Nexa/Nexa-BlackItalic.otf");
  font-style: italic;
  font-display: swap;
  font-weight: 900;
}

@font-face {
  font-family: "GTAmerica";
  src: url("/fonts/GTAmerica/GT-America-Compressed-Bold.otf");
  font-style: normal;
}

@font-face {
  font-family: "ChivoMono";
  src: url("/fonts/ChivoMono/ChivoMono-Bold.ttf");
  font-style: normal;
}