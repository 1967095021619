@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    font-family: Nexa, sans-serif;
  }

  body {
    background-color: black;
  }

  em {
    font-style: normal;
    @apply font-black tracking-wide;
  }
}
